import { useEffect, useRef, useState } from 'react';
import './App.css';
import ContactForm from './ContactForm';
import {useTranslation} from 'react-i18next'
import {motion, useMotionValue} from 'framer-motion'


const imgs=[
  'agronatural.png',
  'agronatural.png'
]

const content = [
  `0`,
  '1'
]




const Dots = ({imgIndex, setImageIndex})=>{
  return <>
      <div className='mt-4 flex w-full justify-center gap-2'>
          {imgs.map((_,idx)=>{
              return <button 
                  className={` h-2 md:h-3 w-2 md:w-3 rounded-full transition-colors bg-neutral-900 ${
                      idx === imgIndex ? 'opacity-60': 'opacity-20'
                  } `}

                  key={idx} 
                  onClick={()=>{
                  setImageIndex(idx)
              }}/>
          })}

      </div>
  </>
}

const DRAG_BUFFER = 50








function App() {
  const {t,i18n} = useTranslation()
  const Images = ()=>{
    return <>
          <div
            className='w-full  p-2  shrink-0 object-cover'
  
            style={{
  
            }}>
                <div className='cormorant flex items-center pl-[10%] pr-[10%] text-2xl  cardsText md:text-6xl italic w-full  border text-center border-black rounded-xl h-[84vh] md:h-[80vh] '> {t('nosotrosCard')}</div>
            </div>
          <div
            className='w-full  p-2  shrink-0 object-cover'
  
            style={{
  
            }}>
                <div className='cormorant flex items-center pl-[10%] pr-[10%] text-2xl cardsText  md:text-6xl italic w-full  text-center border border-black rounded-xl h-[84vh] md:h-[80vh] '> {t('nosotrosCard1')}</div>
            </div>
  
        {/* {imgs.map((imgSrc, indx)=>{
            return <div
            key={indx}
            className='w-full  p-2  shrink-0 object-cover'
  
            style={{
  
            }}>
                <div className='cormorant flex items-center pl-[10%] pr-[10%] text-2xl md:text-6xl italic w-full  border border-black rounded-xl h-[80vh] '> {content[indx]}</div>
            </div>
        })} */}
  
    </>
  }

  const Scroller =()=>{

    const [imgIndex, setImageIndex] = useState(0)
    const [dragging, setDragging] = useState(false)
  
    const dragX = useMotionValue(0)
  
    const onDragStart = ()=>{
        setDragging(true)
    }
  
    const onDragEnd =()=>{
        setDragging(false)
  
        const x = dragX.get()
        if(x <= -DRAG_BUFFER && imgIndex < imgs.length -1 ){
            setImageIndex((prev) => prev + 1)
        } else if(x>= DRAG_BUFFER && imgIndex > 0){
            setImageIndex((prev)=>
                prev -1
            )
        }
    }
  
  
    return <>
        <div className ='relative overflow-hidden  py-8'>
  
            <motion.div drag='x' 
            
            dragConstraints={{
                left: 0,
                right: 0
            }} 
            
            animate={{
                translateX: `-${imgIndex * 100}%`
            }}
  
            transition={{
                type: 'spring',
                mass:3,
                stiffness: 400,
                damping: 50
            }}
  
            style={{x: dragX}}
  
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
  
            className='flex items-center cursor-grab active:cursor-grabbing'>
  
                <Images />
  
            </motion.div>
            <Dots imgIndex={imgIndex} setImageIndex={setImageIndex} />
        </div>
  
    
    </>
  
  }


  const[wc,setWc] = useState(false)
  // const[bc,setBc] = useState(false)
  // const[m,setM] = useState(false)
  // const[ch,setCh] = useState(false)
  // const[fr,setFr] = useState(false)



  const CardsImg = ()=>{
    return <div className='flex border border-green-950 items-center justify-center w-8 left-6 mt-6 h-8 rounded-full absolute opacity-50'>
      <p className='text-green-950 cormorant text-xl'>i</p>
    </div>
  }

  const languagesArray = ['es','en', 'de']

  const [activeLn, setActiveLn] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(()=>{
    console.log('language has been changed', ` ${languagesArray[activeLn]}`);

    i18n.changeLanguage(`${languagesArray[activeLn]}`)
  },[activeLn])

  const handleItemClick = (value)=>{
    setIsOpen(prev=>!prev)
    // setActiveLn(value)
    console.log(value);
  }

  const productosRef = useRef()
  const contactoRef = useRef()
  const nosotrosRef = useRef()

  return (
    <>
    <div className='flex w-full flex-col items-center '>

    
      <header className='w-[90%] md:w-[80%] h-14 mt-6 md:mt-14 flex items-center  md:justify-between '>
        <img src='agronatural.png' className='h-8 md:h-14'/>
        <div className='flex space-x-3 md:space-x-6 archivo text-lg'>
            <p onClick={()=>{
            contactoRef.current.scrollIntoView({behavior: 'smooth'})
            console.log('hie');
          }} className='hidden md:inline ml-6 md:ml-0'>{t('contacto')}</p>
            <p onClick={()=>{
            nosotrosRef.current.scrollIntoView({behavior: 'smooth'})
          }} className='hidden md:inline'>{t('nosotros')}</p>
            
            <p className='pl-8' onClick={()=>{
            productosRef.current.scrollIntoView({behavior: 'smooth'})
          }}>{t('productos')}</p>

            <div className="dropdown-menu hover:cursor-pointer">
              <ul className='absolute'>
                <li class='underline font-bold italic' onClick={() => handleItemClick(0)}>{languagesArray[activeLn]}</li>
                {isOpen && <>
                  <li onClick={()=>{
                    setActiveLn(2)
                    setIsOpen(prev=>!prev)
                  }}>de</li>
                  <li onClick={()=>{
                    setActiveLn(1)
                    setIsOpen(prev=>!prev)
                  }}>en</li>
                  <li onClick={()=>{
                    setActiveLn(0)
                    setIsOpen(prev=>!prev)
                  }}>es</li>
                
                </>}
              </ul>
            </div>

        </div>
      </header>



      <main className='w-[80%]  '>
        
        {/* PORTADA */}
        <section >
          <h1 className='mt-20 cormorant text-5xl md:text-8xl italic font-bold  md:w-[80%]'>
          {t('Misión, Visión')}
          </h1>
          <h2 className='archivo text-2xl  md:text-3xl mt-10 md:mt-16 mb-4 md:mb-10  md:w-[80%]'>
            {t('Nuestro compromiso')}
          </h2>
        </section>

        <section ref={nosotrosRef}>
          <Scroller  />
        </section>


        <section className='mt-20 md:mt-40'>
          <h2 ref={productosRef} className='cormorant font-bold text-3xl md:text-5xl'>{t('Algunosde')}</h2>
          <p  className='cormorant italic text-lg md:text-xl opacity-80'>{t('Lasimagenes')}</p>

          <div  className='mt-12 flex flex-col md:flex-row  md:space-x-6' >
            <div className='hover:cursor-pointer relative rounded-xl mb-8 md:mb-0 w-[100%] md:w-[50%] h-[80vh]' onClick={()=>{
              setWc((prev)=>!prev)
            }} style={{backgroundImage: 'url(./coffewhite1.jpg)', backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'rgba(255,255,255,0.1)'}}>
              { wc && <div className='w-full h-full flex justify-center items-center bg-[#fff3e6d1] rounded-xl'>
                <div className='max-w-[80%] md:max-w-[60%]'>


                <p className='cormorant font-bold text-2xl mt-5 md:mt-0 md:text-3xl'>{t('Cafeblanco')}</p>
                <p className='archivo text-md md:text-xl mt-2'>{t('Cafeblancodes')}</p>
                <p className='text-xl mt-10 md:mt-16 cormorant font-bold md:text-3xl'>{t('CafeblancoFl')}</p>
                <p className='archivo text-md md:text-xl'>{t('CafeblancoFlnotes')}</p>
                </div>
              </div>
              }
              {!wc&&<CardsImg />}
            </div>
            <div className='hover:cursor-pointer relative rounded-xl w-[100%]  md:w-[50%] h-[80vh]' onClick={()=>{
              setWc((prev)=>!prev)
            }} style={{backgroundImage: 'url(./coffebrown1.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}}>

{/* { wc && <div className='w-full h-full flex justify-center items-center bg-[#fff3e6d1] rounded-xl'>
                <div className='max-w-[80%] md:max-w-[60%]'>
                <p className='cormorant font-bold text-2xl mt-5 md:mt-0 md:text-3xl'>{t('Cafeblanco')}</p>
                <p className='archivo text-md md:text-xl mt-2'>{t('Cafenegrocodes')}</p>
                <p className='text-xl mt-10 md:mt-16 cormorant font-bold md:text-3xl'>{t('CafeblancoFl')}</p>
                <p className='archivo text-md md:text-xl'>{t('CafenegroFlnotes')}</p>
                </div>
</div>
} */}
{/* {!wc&&<CardsImg />} */}
            </div>
            
          </div>
          <div className='md:flex-row flex-col flex relative md:space-x-6 mt-6'>
            <div onClick={()=>{
              setWc((prev)=>!prev)
            }} style={{backgroundImage: 'url(./honey.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}} className='hover:cursor-pointer rounded-xl w-[100%] md:w-[33.33%] h-[40vh] bg-red-100 '>
              {wc && <div className='w-full flex items-center justify-center h-full rounded-xl bg-[#fff3e6d1]'>

                  <div className='w-[80%]'>
                    <p className='cormorant text-2xl md:text-3xl font-bold'>{t('Miel')}</p>
                    <p className='archivo text-md md:text-lg mt-2'>{t('Mieldes')}</p>
                  </div>

                </div>}
                {!wc && <CardsImg />}
            </div>
            <div style={{backgroundImage: 'url(./chocolate.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}} onClick={()=>{
              setWc((prev)=>!prev)
            }} className='hover:cursor-pointer relative rounded-xl  w-[100%] md:w-[33.33%] mt-4 mb-4 md:mt-0 md:mb-0 h-[40vh] bg-red-200 '>
            {wc && <div className='w-full flex items-center justify-center h-full rounded-xl bg-[#fff3e6d1]'>

              <div className='w-[80%]'>
                <p className='cormorant text-2xl md:text-3xl font-bold'>{t('Chocolate Natural')}</p>
                <p className='archivo text-md md:text-lg mt-2'>{t('Chocolate Natural description')}</p>
              </div>

              </div>}
              {!wc && <CardsImg />}
            </div>
            <div onClick={()=>{
              setWc((prev)=>!prev)
            }} style={{backgroundImage: 'url(./frutas.jpg)',  backgroundSize: 'cover', backgroundPosition: 'center'}} className='hover:cursor-pointer relative rounded-xl  w-[100%] md:w-[33.33%] h-[40vh] bg-red-300 '>
            
            {wc && <div className='w-full flex items-center justify-center h-full rounded-xl bg-[#fff3e6d1]'>

<div className='w-[80%]'>
  <p className='cormorant text-2xl md:text-3xl font-bold'>{t('Frutas y Verduras')}</p>
  <p className='archivo text-md md:text-lg mt-2'>{t('Frutasnotes')}</p>
</div>
</div>}

{!wc &&<CardsImg />}
            </div>
          </div>
            {/* <h2>{t('PalabrasTitulo')}</h2> */}
            <p className=' mt-20 archivo text-2xl md:text-xl'>{t('Palabras')}</p>
        </section>


      </main>

      <footer className='w-[80%] flex-col md:flex-row flex mt-20  md:mb-20 md:mt-40 mb-20'>
        <p className='w-[100%] md:w-[40%] cormorant font-bold text-lg md:mb-0 mb-10 md:text-2xl'>{t('FinalDes')}</p>
        <div ref={contactoRef} className=' w-[100%] md:w-[60%] md:pl-20'>
          <div  className=' w-full h-full'>
            <ContactForm />
          </div>
        </div>
      </footer>
      </div>
    </>

    

  );
}

export default App;
