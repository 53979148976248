import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Misión, Visión": "Mission, Vision and Values..: Nourishing, Feeding and sweetening in a healthy way",
      'Nuestro compromiso': 'We are committed to offering products of the highest quality, made with natural ingredients and carefully supervised processes.',
      'contacto': 'contact',
      'productos': 'products',
      'nosotros': 'about us',
      'nosotrosCard': 'It is not only about selling food, the products supplied to our customers will benefit them in a natural way, especially we want to supplement the nutrition of two very neglected segments of the consumer population, children from six months to 10 years old and people over 60 years old without neglecting the rest of the customers between those ages.',
      'nosotrosCard1': 'Our products carry the responsibility of providing nutrition, nourishment and flavor without harming health, we care about generating life and health to our consumers. Our collaborators in the field are benefited with fair incomes, each product is supervised throughout the production chain.',
      'Algunosde': 'Some of Our Products',
      'Lasimagenes': '*The pictures are for illustrative purposes, the packaging may vary.',
      'Cafeblanco': 'Premium coffee from Colombia',
      'Cafeblancodes': 'planted in the mountains of the Central mountain range, by peasants of a long coffee tradition between 5740 and 6890 famsl (1750 and 2100 masl). Hills, mountains and heavy rains give it its distinctive flavor of chocolate, herbs, panela and lemongrass, with a strong character.',
      'CafeblancoFl': 'Common Flavor Notes:',
      'CafeblancoFlnotes': 'Chocolate, Raw sugar, Lemongrass',
      'Cafenegrocodes':'Colombian coffee, without grain selection, natural, no dyes, no chemicals, no preservatives, intense roasting and with the original formulation of the most traditional coffee taken in the world. Coffee with high body and character, dark and that is enough for many cups.',
      'CafenegroFlnotes': 'Low acidity',
      'Miel':'Bee Honey',
      'Mieldes': '100% natural honey with no added chemicals, from the Amazon of Brazil',
      'Chocolate Natural description': 'Our selection of 70% and 80% natural chocolate',
      'Chocolate Natural':'Natural Chocolate',
      'Frutas y Verduras': 'Fruits and Vegetables',
      'Frutasnotes': 'We market a variety of fruits and vegetables including avocados, papayas and bananas.',
      'FinalDes': 'With humble roots and a strong connection to the Latin American community, at Agro Natural we understand that every product we offer has the power to improve the lives of those who consume it. From the selection of inputs to the manufacturing process, every step is guided by the commitment to offer the best to our customers, without compromising health and wellness.'
      
    }
  },
  de: {
    translation: {
      "Misión, Visión": "Mission, Vision, Werte..: Gesund nähren, füttern und süßen",
      'Nuestro compromiso': 'Wir sind bestrebt, Produkte von höchster Qualität anzubieten, die mit natürlichen Inhaltsstoffen und unter genauer Überwachung hergestellt werden.',
      'contacto': 'kontakt',
      'productos': 'produkte',
      'nosotros': 'über uns',
      'nosotrosCard': 'Es geht nicht nur darum, Lebensmittel zu verkaufen, sondern die Produkte, die wir unseren Kunden liefern, sollen ihnen auf natürliche Weise zugute kommen. Wir wollen insbesondere die Ernährung von zwei bisher vernachlässigten Verbrauchergruppen ergänzen, nämlich von Kindern im Alter von sechs Monaten bis 10 Jahren und von Menschen über 60 Jahren, ohne dabei die übrigen Kunden dazwischen zu vernachlässigen.',
      'nosotrosCard1': 'Unsere Produkte tragen die Verantwortung, Nahrung, Nährstoffe und Geschmack zu liefern, ohne der Gesundheit zu schaden; es ist uns ein Anliegen, Leben und Gesundheit für unsere Verbraucher zu fördern. Unsere Mitarbeiter vor Ort profitieren von einem fairen Einkommen, jedes Produkt wird während der gesamten Produktionskette überwacht.',
      'Algunosde': 'Einige unserer Produkte',
      'Lasimagenes': '*Die Bilder sind zur Veranschaulichung, die Verpackung kann variieren.',
      'Cafeblanco': 'Premium-Kaffee aus Kolumbien',
      'Cafeblancodes': 'wird in den Bergen des Zentralgebirges von Bauern mit langer Kaffeetradition zwischen 5740 und 6890 famsl (1750 und 2100 masl) angebaut. Hügel, Berge und starke Regenfälle geben ihm seinen unverwechselbaren Geschmack von Schokolade, Kräutern, Panela und Zitronengras, mit einem starken Charakter.',
      'CafeblancoFl': 'Gemeinsame Geschmacksnoten:',
      'CafeblancoFlnotes': 'Schokolade, Rohzucker, Lemongras',
      'Cafenegrocodes':'Kolumbianischer Kaffee, ohne Getreideselektion, natürlich, ohne Farbstoffe, ohne Chemikalien, ohne Konservierungsstoffe, intensiv geröstet und mit der Originalrezeptur des traditionellsten Kaffees der Welt. Kaffee mit hohem Körper und Charakter, dunkel und das ist genug für viele Tassen.',
      'CafenegroFlnotes': 'Niedriger Säuregehalt',
      'Miel':'Bienenhonig, ',
      'Mieldes': '100% natürlicher Honig ohne Zusatz von Chemikalien, aus dem brasilianischen Amazonasgebiet',
      'Chocolate Natural description': 'Unsere Auswahl an 70% und 80% natürlicher Schokolade',
      'Chocolate Natural':'Natürliche Schokolade',
      'Frutas y Verduras': 'Obst und Gemüse',
      'Frutasnotes': 'Wir vermarkten eine Vielzahl von Obst und Gemüse, darunter Avocados, Papayas und Bananen.',
      'FinalDes': 'Mit bescheidenen Anfängen und einer starken Verbindung zur lateinamerikanischen Gemeinschaft verstehen wir bei Agro Natural, dass jedes von uns angebotene Produkt die Kraft hat, das Leben derer zu verbessern, die es konsumieren. Von der Auswahl der Rohstoffe bis hin zum Herstellungsprozess wird jeder Schritt von der Verpflichtung geleitet, unseren Kunden das Beste zu bieten, ohne Kompromisse bei Gesundheit und Wohlbefinden einzugehen.'
    }
  },
  es:{
    translation:{
        'Misión, Visión': 'Misión, Visión y Valores..: Nutrir, Alimentar y endulzar de forma saludable',
        'Nuestro compromiso': 'Nos comprometemos a ofrecer productos de la más alta calidad, elaborados con ingredientes naturales y procesos cuidadosamente supervisados.',
        'contacto': 'contacto',
        'productos': 'productos',
        'nosotros': 'nosotros',
        'nosotrosCard': 'No solo se trata de vender comida, los productos que se suministran a nuestros clientes los beneficiarán de forma natural, en especial queremos complementar la nutrición de dos segmentos muy descuidados de la población de consumo, la niñez desde los seis meses hasta los 10 años y las personas mayores de 60 años sin descuidar al resto de clientes comprendidos entre esas edades.',
        'nosotrosCard1': 'Nuestros productos conllevan la responsabilidad de aportar nutrición, alimentar, dar sabor sin dañar la salud, nos importa generar vida y salud a nuestros consumidores. Nuestros colaboradores en el campo son beneficiados con ingresos justos, cada producto es supervisado en toda la cadena productiva.',
        'Algunosde': 'Algunos de Nuestros Productos',
        'Lasimagenes': '*Las imagenes son con fin ilustrativo, los empaques podrían variar',
        'Cafeblanco': 'Café premium de Colombia',
        'Cafeblancodes': 'Plantado en las montañas de la cordillera Central, por campesinos de larga tradición cafetera, entre 5740 y 6890 famsl (1750 y 2100 msnm). Las colinas, montañas y fuertes lluvias le dan su sabor distintivo a chocolate, hierbas, panela y limoncillo, con un fuerte carácter.',
        'CafeblancoFl': 'Notas aromáticas comunes:',
        'CafeblancoFlnotes': 'Chocolate, azúcar sin refinar, hierba limón',
        'Cafenegrocodes':'Café de Colombia, sin selección de grano, natural, sin colorantes, sin químicos, sin conservantes, de tueste intenso y con la formulación original del café más tradicional que se toma en el mundo. Café con alto cuerpo y carácter, oscuro y que alcanza para muchas tazas.',
        'CafenegroFlnotes': 'Baja acidez',
        'Miel':'Miel de abeja',
        'Mieldes': 'Miel 100% natural sin químicos añadidos, del Amazonas de Brasil',
        'Chocolate Natural description': 'Nuesta selección de 70% y 80% chocolate natural',
        'Chocolate Natural':'Chocolate Natural',
        'Frutas y Verduras': 'Frutas y Verduras',
        'Frutasnotes': 'Comercializamos una variedad de frutas y verduras incluyendo aguacates, papayas y bananos',
        'FinalDes': 'Con raíces humildes y una fuerte conexión con la comunidad latinoamericana, en Agro Natural entendemos que cada producto que ofrecemos tiene el poder de mejorar la vida de quienes lo consumen. Desde la selección de insumos hasta el proceso de elaboración, cada paso es guiado por el compromiso de ofrecer lo mejor a nuestros clientes, sin comprometer la salud ni el bienestar.',
        'PalabrasTitulo': 'Dentro de nuestro ámbito: ',
        'Palabras': 'Los sabores de Latinoamérica se entrelazan en una experiencia culinaria única. Desde el café guatemalteco y colombiano, con su aroma robusto y profundo, hasta la dulzura del aguacate, mango y papaya, la región ofrece una variedad de delicias naturales. La miel pura de Brasil y México añade un toque de dulzura a los platos, mientras que la semilla de cacao se convierte en barras de chocolate que satisfacen los antojos más exigentes. Con opciones que van desde el 70% hasta el 80% de cacao, el chocolate es un placer para los amantes del dulce. Para aquellos que prefieren lo fresco, la piña, el melón y el banano son opciones ideales. Y si buscas un toque picante, el chile y el pimiento agregarán ese toque característico. Las mermeladas de fresa, combinaciones de zanahoria y tamarindo, ofrecen opciones vibrantes y deliciosas para el desayuno o el postre. Además, la stevia ofrece una alternativa natural para endulzar tus bebidas sin calorías adicionales. Descubre la riqueza de los sabores latinoamericanos en cada bocado, y disfruta de una experiencia gastronómica que celebra la diversidad y la autenticidad de la región.'
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "es", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;