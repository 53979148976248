import { useForm, ValidationError } from "@formspree/react";
import { useState } from "react";

export default function ContactForm() {
  const [state, handleSubmit] = useForm("mbjndnjv");
  const [fieldValue, setFieldValue] = useState('')
  const [isFieldNotEmpty, setIsFieldNotEmpty] = useState(false);

  const handleFieldChange = (event) => {
    const value = event.target.value;
    setFieldValue(value)
    setIsFieldNotEmpty(value.length !== 0);
  };

  console.log(isFieldNotEmpty);

  if (state.succeeded) {
    return <p>Thank you for contacting us!</p>;
  }

  return (

    <form onSubmit={handleSubmit}>


      <div className="w-full  [&>*]:w-full [&>*]:rounded-sm  space-y-2">
        <input className='h-8 pl-3' id="email" type="email" name="email" placeholder="email" value={fieldValue} onChange={handleFieldChange}/>
        <ValidationError prefix="Email" required={true} field="email" errors={state.errors} />
        <ValidationError prefix="Message" field="message" errors={state.errors} />
        <input className='h-8 pl-3' name="name" id="name" placeholder="name"></input>
        <textarea placeholder="message" className="pl-3 pr-3 h-20" id="mensage" name="mensage" />
      
      </div>
      

      <button className={`${isFieldNotEmpty ? 'block' : 'hidden'} rounded-full bg-black mt-4 text-white p-2 w-full`} type="submit" disabled={state.submitting}>
        Submit
      </button>
      <ValidationError errors={state.errors} />
        
    </form>
  );
}
